import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { INoticeParams, MeetingWithStudentWillStartNoticeParams } from 'lingo2-models';
import { EModifier, IBaseNotification } from '../../base-notification/base-notification.component';
import { BaseComponent } from '../base.component';

@Component({
  selector: 'app-meeting-with-student-will-start',
  templateUrl: './meeting-with-student-will-start.component.html',
  styles: [''],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MeetingWithStudentWillStartComponent extends BaseComponent {
  public params: MeetingWithStudentWillStartNoticeParams;
  public base: IBaseNotification;

  @Input() set notification(notification: INoticeParams) {
    this.params = MeetingWithStudentWillStartNoticeParams.createInstance(notification.params);
    this.base = {
      title: 'notifications.meeting_with_student_will_start.title',
      icon: 'notice-clock',
      created_at: notification.created_at,
      modifier: EModifier.blue,
    };
  }
}
