import { Component, Input, ChangeDetectionStrategy, ChangeDetectorRef } from '@angular/core';
import { Router } from '@angular/router';
import { ClassroomsService } from '@core/services';
import { INoticeParams } from 'lingo2-models';
import { EModifier, IBaseNotification } from '../../base-notification/base-notification.component';
import { BaseComponent } from '../base.component';

@Component({
  selector: 'app-classroom-leave',
  templateUrl: './classroom-leave.component.html',
  styles: [''],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ClassroomLeaveComponent extends BaseComponent {
  public params: INoticeParams;
  public base: IBaseNotification;
  public classroomRoute = ClassroomsService.classroomRoute;

  @Input() set notification(notification: INoticeParams) {
    this.params = notification.params;
    this.base = {
      title: 'notifications.classroom-leave.title',
      icon: 'notice-invite',
      created_at: notification.created_at,
      modifier: EModifier.yellow,
    };
  }

  constructor(public router: Router, protected readonly cdr: ChangeDetectorRef) {
    super(cdr);
  }

  public get title() {
    const routeUrl = this.classroomRoute(this.params.classroom).join('/');
    return `<a class='link-base' href='${routeUrl}'>${this.params.classroom?.title}</a>`;
  }

  public navigate() {
    this.router.navigate(this.classroomRoute(this.params.classroom));
  }
}
