import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { INoticeParams, YouSuccessfullyEnrolledInMeetingNoticeParams } from 'lingo2-models';
import { EModifier, IBaseNotification } from '../../base-notification/base-notification.component';
import { BaseComponent } from '../base.component';

@Component({
  selector: 'app-you-successfully-enrolled-in-meeting',
  templateUrl: './you-successfully-enrolled-in-meeting.component.html',
  styles: [''],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class YouSuccessfullyEnrolledInMeetingComponent extends BaseComponent {
  public params: YouSuccessfullyEnrolledInMeetingNoticeParams;
  public base: IBaseNotification;

  @Input() set notification(notification: INoticeParams) {
    this.params = YouSuccessfullyEnrolledInMeetingNoticeParams.createInstance(notification.params);
    this.base = {
      title: 'notifications.you_successfully_enrolled_in_meeting.title',
      icon: 'notice-clock',
      created_at: notification.created_at,
      modifier: EModifier.blue,
    };
  }
}
