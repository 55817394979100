<app-base-notification [base]="base">

  <div clickToRelativeUrl
       [innerHTML]="'notifications.classroom_new_participant_2.text' | translate: {
         'user_fullname': user,
         'classroom_title': classroom_title
       }"></div>

  <on-ui-button class="primary outline"
                [label]="'notifications.classroom_new_participant_2.action'"
                [routerLink]="params.classroom_url | relativeUrl">
  </on-ui-button>

</app-base-notification>
