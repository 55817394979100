import { Component, Input, ChangeDetectionStrategy } from '@angular/core';
import { Router } from '@angular/router';
import { ClassroomsService } from '@core/services';
import { IMeetingInviteNotice, INoticeParams } from 'lingo2-models';
import { EModifier, IBaseNotification } from '../../base-notification/base-notification.component';

@Component({
  selector: 'app-classroom-removed',
  templateUrl: './classroom-removed.component.html',
  styles: [''],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ClassroomRemovedComponent {
  public params: INoticeParams;
  public base: IBaseNotification;
  public classroomRoute = ClassroomsService.classroomRoute;

  @Input() set notification(notification: INoticeParams) {
    this.params = notification.params;
    this.base = {
      title: 'notifications.classroom-removed.title',
      icon: 'notice-invite',
      created_at: notification.created_at,
      modifier: EModifier.yellow,
    };
  }

  public get title() {
    const routeUrl = this.classroomRoute(this.params.classroom).join('/');
    return `<a class='link-base' href='${routeUrl}'>${this.params.classroom?.title}</a>`;
  }
}
