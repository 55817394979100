import { Component, OnInit, ChangeDetectionStrategy, Input } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Observable } from 'rxjs';
import { NotificationService } from '../notification.service';

export enum EModifier {
  yellow = 'yellow',
  blue = 'blue',
  red = 'red',
  green = 'green',
}

export interface IBaseNotification {
  title: string;
  icon: string;
  created_at: Date;
  modifier: EModifier;
}

/**
 * Когда добавляешь новый тип уведомления, добавь его в список поддерживаемых типов уведомений
 *
 * @see supportedNotices
 */

@Component({
  selector: 'app-base-notification',
  templateUrl: './base-notification.component.html',
  styleUrls: ['./base-notification.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BaseNotificationComponent implements OnInit {
  @Input() public base: IBaseNotification;

  public hasClose$: Observable<boolean>;
  public isHovered: boolean;

  constructor(private translateService: TranslateService, private notificationService: NotificationService) {}

  ngOnInit() {
    this.hasClose$ = this.notificationService.hasClose$;
  }

  public get showFullTitle() {
    return this.isHovered && this.translateService.instant(this.base.title).length > 25;
  }
  public close(): void {
    this.notificationService.close();
  }
}
