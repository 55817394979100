import { Component, Input, ChangeDetectionStrategy } from '@angular/core';
import { format } from 'date-fns';
import { IMeetingInviteNotice, INoticeParams } from 'lingo2-models';
import { EModifier, IBaseNotification } from '../../base-notification/base-notification.component';
import { BaseComponent } from '../base.component';

@Component({
  selector: 'app-meeting-invite',
  templateUrl: './meeting-invite.component.html',
  styles: [''],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MeetingInviteComponent extends BaseComponent {
  public params: IMeetingInviteNotice;
  public base: IBaseNotification;

  @Input() set notification(notification: INoticeParams) {
    this.params = notification.params;
    this.base = {
      title: 'notifications.meeting_invite.title',
      icon: 'notice-invite',
      created_at: notification.created_at,
      modifier: EModifier.yellow,
    };
  }

  public get title() {
    return `<a class='link-base' href='${this.params.meeting_join_url}'>${this.params.meeting_title} - ${format(
      new Date(this.params.meeting_begin_at),
      'MM/dd/yyyy HH:mm',
    )}</a>`;
  }
}
