import { Component, Input, ChangeDetectionStrategy } from '@angular/core';
import { Router } from '@angular/router';
import { RelativeUrlPipe } from '@core/components/notification/pipes/relative-url.pipe';
import { ContractProlongOrderUnpaidNoticeParams, INoticeParams } from 'lingo2-models';
import { IBaseNotification, EModifier } from '../../base-notification/base-notification.component';

@Component({
  selector: 'app-contract-prolong-order-unpaid',
  templateUrl: './contract-prolong-order-unpaid.component.html',
  styleUrls: ['./contract-prolong-order-unpaid.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ContractProlongOrderUnpaidComponent {
  public params: ContractProlongOrderUnpaidNoticeParams;
  public base: IBaseNotification;

  @Input() set notification(notification: INoticeParams) {
    this.params = ContractProlongOrderUnpaidNoticeParams.createInstance(notification.params);
    this.base = {
      title: 'notifications.contract-prolong-order-unpaid.title',
      icon: 'notice-clock',
      created_at: notification.created_at,
      modifier: EModifier.yellow,
    };
  }

  public constructor(private router: Router) {}

  // TODO: Вынести в сервис или переделать в пайп
  public processLinks(e) {
    const element: HTMLElement = e.target;
    e.preventDefault();
    const link = new RelativeUrlPipe().transform(element.getAttribute('href'));
    void this.router.navigate([link]);
  }
}
