<!-- @see supportedNotices -->
<div
  class="notification notification_{{base.modifier}}"
  [class.notification_has-close]="hasClose$ | async"
  (mouseover)="isHovered = true"
  (mouseout)="isHovered = false">
  <div class="notification__header" [class.full-title]="showFullTitle">
    <span class="notification__title">
      <div class="icon-wrap">
        <app-icon class="icon" width="20" height="20" file="notifications" [icon]="base.icon"></app-icon>
      </div>
      <span class="title">{{ base.title | translate }}</span>
    </span>

    <span class="notification__time"> {{ base.created_at | date : 'HH:mm' }} </span>

    <span class="notification__close" (click)="close()">
      <app-icon class="icon" width="12" height="12" icon="close-sm"></app-icon>
    </span>
  </div>

  <div class="notification__content">
    <ng-content></ng-content>
  </div>
</div>
