import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { INoticeParams, MeetingWithTeacherWillStartNoticeParams } from 'lingo2-models';
import { EModifier, IBaseNotification } from '../../base-notification/base-notification.component';
import { BaseComponent } from '../base.component';

@Component({
  selector: 'app-meeting-with-teacher-will-start',
  templateUrl: './meeting-with-teacher-will-start.component.html',
  styles: [''],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MeetingWithTeacherWillStartComponent extends BaseComponent {
  public params: MeetingWithTeacherWillStartNoticeParams;
  public base: IBaseNotification;

  @Input() set notification(notification: INoticeParams) {
    this.params = MeetingWithTeacherWillStartNoticeParams.createInstance(notification.params);
    this.base = {
      title: 'notifications.meeting_with_teacher_will_start.title',
      icon: 'notice-clock',
      created_at: notification.created_at,
      modifier: EModifier.blue,
    };
  }
}
