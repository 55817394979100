<app-base-notification [base]="base">
  <p class="class-info">{{ text | translate }}
    <a *ngIf="firstMeeting" [routerLink]="firstMeeting.meeting_join_url | relativeUrl">{{ firstMeeting.meeting_title }}</a>
  </p>

  <div class="meeting-info" *ngIf="firstMeeting">
    <ng-template [ngIf]="isSingle">
      <p>{{ 'notifications.contract_signed_up_student.info_onetime_date' | translate : {
          begin_at_local: firstMeeting.meeting_begin_at | date : 'dd/MM/yyyy HH:mm'
      } }}</p>
    </ng-template>

    <ng-template [ngIf]="isCourse">
      <p>{{ 'notifications.contract_signed_up_student.info_course_number' | translate: {
          count: params.meetings.length
        } }}</p>
      <p>{{ 'notifications.contract_signed_up_student.info_course_date' | translate: {
          begin_at_local: firstMeeting.meeting_begin_at | date : 'dd/MM/yyyy HH:mm'
        } }}</p>
    </ng-template>

    <ng-template [ngIf]="isRegular">
      <p>{{ 'notifications.contract_signed_up_student.info_regular_weekly' | translate: {
          weekdays: firstMeeting.meeting_begin_at | date : 'EEEE',
        } }}</p>
      <p>{{ 'notifications.contract_signed_up_student.info_regular_date' | translate: {
          begin_at_local: firstMeeting.meeting_begin_at | date : 'dd/MM/yyyy HH:mm'
        } }}</p>
    </ng-template>
  </div>

  <div clickToRelativeUrl
       [innerHTML]="'notifications.contract_signed_up_student.teacher' | translate: {
         teacher_fullname: teacher,
       }"></div>
</app-base-notification>

