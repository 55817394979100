import { Component, OnInit, Input, ChangeDetectionStrategy } from '@angular/core';

import { LibraryRouter } from '@models/library.router';
import { Collection, ModerationStatusEnum, INoticeParams } from 'lingo2-models';
import { IBaseNotification, EModifier } from '../../base-notification/base-notification.component';

@Component({
  selector: 'app-moderation-update',
  templateUrl: './moderation-update.component.html',
  styles: [''],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ModerationUpdateComponent implements OnInit {
  public params: INoticeParams;
  public base: IBaseNotification;
  public collectionRoute = LibraryRouter.collectionRouteUniversal;
  public collectionUrl: string;

  public translationText = '';
  public isVisible = false;

  @Input() set notification(notification: INoticeParams) {
    this.params = notification.params;

    const statusText = ModerationStatusEnum[this.params.item.moderation_status];
    const isApproved = this.params.item.moderation_status === ModerationStatusEnum.approved;

    this.translationText = `notifications.collection-${statusText}.text`;
    this.isVisible =
      this.params.item.moderation_status !== ModerationStatusEnum.default &&
      this.params.item.moderation_status !== this.params.moderation_status_before;

    this.base = {
      title: `notifications.collection-${statusText}.title`,
      icon: isApproved ? 'notice-invite' : 'notice-cancel',
      created_at: notification.created_at,
      modifier: EModifier.yellow,
    };
  }

  constructor() {}

  ngOnInit() {
    this.collectionUrl = this.collectionRoute(this.params.item as Collection);
  }
}
