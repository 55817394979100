import { Component, Input, ChangeDetectionStrategy } from '@angular/core';
import { ContractSignedUpStudentNoticeParams, INoticeParams } from 'lingo2-models';
import { IBaseNotification, EModifier } from '../../base-notification/base-notification.component';
import { BaseComponent } from '../base.component';

@Component({
  selector: 'app-contract-signed-up-student',
  templateUrl: './contract-signed-up-student.component.html',
  styleUrls: ['./contract-signed-up-student.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ContractSignedUpStudentComponent extends BaseComponent {
  public params: ContractSignedUpStudentNoticeParams;
  public base: IBaseNotification;

  @Input() set notification(notification: INoticeParams) {
    this.params = ContractSignedUpStudentNoticeParams.createInstance(notification.params);
    this.base = {
      title: 'notifications.contract_signed_up_student.title',
      icon: 'notice-clock',
      created_at: notification.created_at,
      modifier: EModifier.blue,
    };
  }

  public get text(): string {
    if (this.isSingle) {
      return 'notifications.contract_signed_up_student.text_lesson';
    }

    if (this.isCourse) {
      return 'notifications.contract_signed_up_student.text_course';
    }

    if (this.isRegular) {
      return 'notifications.contract_signed_up_student.text_regular';
    }

    return '';
  }

  public get firstMeeting() {
    return this.params.meetings[0];
  }
}
