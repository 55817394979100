<app-base-notification [base]="base">
  <div [innerHTML]="'notifications.meeting_will_start.text' | translate: {
    'delay_to_begin_min': delayToBeginMin,
    'meeting_title': title,
  }"></div>

  <on-ui-button class="primary"
                [label]="'notifications.meeting_will_start.action'"
                [routerLink]="params.meeting_join_url | relativeUrl">
  </on-ui-button>
</app-base-notification>
