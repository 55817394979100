import { Component, Input, ChangeDetectionStrategy } from '@angular/core';
import { BaseComponent } from '@core/components/notification/notifications/base.component';
import { INoticeParams, MeetingComplaintResolvedTeacherNoticeParams } from 'lingo2-models';
import { EModifier, IBaseNotification } from '../../base-notification/base-notification.component';

@Component({
  selector: 'app-meeting-complaint-resolved-teacher',
  templateUrl: './meeting-complaint-resolved-teacher.component.html',
  styles: [''],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MeetingComplaintResolvedTeacherComponent extends BaseComponent {
  public params: MeetingComplaintResolvedTeacherNoticeParams;
  public base: IBaseNotification;

  @Input() set notification(notification: INoticeParams) {
    this.params = notification.params;
    this.base = {
      title: 'notifications.meeting_complaint_resolved_teacher.title',
      icon: 'notice-cancel',
      created_at: notification.created_at,
      modifier: null,
    };
  }
}
