import { Component, Input, ChangeDetectionStrategy } from '@angular/core';
import { IContentCommentNotice, INoticeParams } from 'lingo2-models';
import { IBaseNotification } from '../../base-notification/base-notification.component';
import { BaseComponent } from '../base.component';

@Component({
  selector: 'app-content-comment',
  templateUrl: './content-comment.component.html',
  styles: [''],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ContentCommentComponent extends BaseComponent {
  public params: IContentCommentNotice;
  public base: IBaseNotification;

  @Input() set notification(notification: INoticeParams) {
    this.params = notification.params as IContentCommentNotice;
    this.base = {
      title: 'notifications.content_comment.title',
      icon: 'notice-comment',
      created_at: notification.created_at,
      modifier: null,
    };
  }
}
