import { Component, Input, ChangeDetectionStrategy, ChangeDetectorRef } from '@angular/core';
import { Router } from '@angular/router';
import { BaseComponent } from '@core/components/notification/notifications/base.component';
import { AccountService } from '@core/services';
import { ChangableComponent } from '@models/changable.component';
import { ErrorNotificationService } from '@shared/error-notification/error-notification.service';
import { ContractSignedUpNoticeParams, INoticeParams } from 'lingo2-models';
import { takeUntil } from 'rxjs/operators';
import { IBaseNotification, EModifier } from '../../base-notification/base-notification.component';

@Component({
  selector: 'app-contract-signed-up',
  templateUrl: './contract-signed-up.component.html',
  styleUrls: ['./contract-signed-up.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ContractSignedUpComponent extends BaseComponent {
  public params: ContractSignedUpNoticeParams;
  public base: IBaseNotification;
  public studentLink: string;
  public studentName: string;

  @Input() set notification(notification: INoticeParams) {
    this.params = ContractSignedUpNoticeParams.createInstance(notification.params);
    this.prepareNotify(notification);
  }

  public constructor(
    public errorNotificationService: ErrorNotificationService,
    private accountService: AccountService,
    protected readonly cdr: ChangeDetectorRef,
  ) {
    super(cdr);
  }

  private prepareNotify(notify: INoticeParams) {
    this.base = {
      title: 'notifications.contract-signed-up.title',
      icon: 'notice-clock',
      created_at: notify.created_at,
      modifier: EModifier.blue,
    };

    this.accountService
      .getUserById(this.params.user_id)
      .pipe(takeUntil(this.destroyed$))
      .subscribe({
        next: (user) => {
          this.studentLink = AccountService.accountRoute(user).join('/');
          this.studentName = AccountService.getUserName(user);
          this.detectChanges();
        },
        error: (error) => {
          this.errorNotificationService.captureError(error, 'LOAD-SOMEDATA');
        },
      });
  }
}
