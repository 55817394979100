import { ChangeDetectorRef, Directive } from '@angular/core';
import { Router } from '@angular/router';
import { RelativeUrlPipe } from '@core/components/notification/pipes/relative-url.pipe';
import { ChangableComponent } from '@models/changable.component';
import { EUserServiceType } from 'lingo2-models';
import { INoticeParams } from 'lingo2-models/dist/messaging/interfaces/notice-params';
@Directive()
export class BaseComponent extends ChangableComponent {
  public params: INoticeParams;

  constructor(protected readonly cdr: ChangeDetectorRef) {
    super(cdr);
  }

  public get user() {
    return `<a class='user-fullname-link' target='_blank' href='${this.filteredUrl(this.params.user_url)}'>${
      this.params.user_fullname
    }</a>`;
  }

  public get student() {
    return `<a class='user-fullname-link' target='_blank' href='${this.filteredUrl(this.params.student_url)}'>${
      this.params.student_fullname
    }</a>`;
  }

  public get participant() {
    return `<a class='user-fullname-link' target='_blank' href='${this.filteredUrl(this.params.participant_url)}'>${
      this.params.participant_fullname
    }</a>`;
  }

  public get teacher() {
    return `<a class='user-fullname-link' target='_blank' href='${this.filteredUrl(this.params.teacher_url)}'>${
      this.params.teacher_fullname
    }</a>`;
  }

  public get author() {
    return `<a class='user-fullname-link' target='_blank' href='${this.filteredUrl(this.params.author_url)}'>${
      this.params.author_fullname
    }</a>`;
  }

  public get title() {
    return `<a class='link-base' href='${this.filteredUrl(this.params.meeting_join_url)}'>${
      this.params.meeting_title
    }</a>`;
  }

  public get contentTitle() {
    return `<a class='link-base' href='${this.filteredUrl(this.params.content_url)}'>${this.params.content_title}</a>`;
  }

  public get meetingBeginAt() {
    return this.params.meeting_begin_at;
  }

  public get delayToBeginMin() {
    return this.params.delay_to_begin_min;
  }

  public get isSingle() {
    return this.params.service_type === EUserServiceType.single;
  }

  public get isCourse() {
    return this.params.service_type === EUserServiceType.course || this.params.service_type === EUserServiceType.mini;
  }

  public get isRegular() {
    return this.params.service_type === EUserServiceType.regular;
  }

  public filteredUrl(url: string) {
    const filterPipe = new RelativeUrlPipe();
    return filterPipe.transform(url);
  }
}
