<div *ngIf="debug$ | async">
  <pre style="color: red;">{{ notification | json}}</pre>
</div>

<ng-container [ngSwitch]="notification?.type">
<!-- @see supportedNotices -->
<!--  Meeting notifications -->
  <app-meeting-reservation
    *ngSwitchCase="noticeTypes.meeting_reservation" [notification]="notification"></app-meeting-reservation>
  <app-teacher-rescheduled-meeting
    *ngSwitchCase="noticeTypes.teacher_rescheduled_meeting"
    [notification]="notification"></app-teacher-rescheduled-meeting>
  <app-meeting-started
    *ngSwitchCase="noticeTypes.meeting_started" [notification]="notification"></app-meeting-started>
  <app-my-meeting-started
    *ngSwitchCase="noticeTypes.my_meeting_started" [notification]="notification"></app-my-meeting-started>
  <app-meeting-invite
    *ngSwitchCase="noticeTypes.meeting_invite" [notification]="notification"></app-meeting-invite>
  <app-meeting-purchase-canceled
    *ngSwitchCase="noticeTypes.meeting_purchase_was_canceled" [notification]="notification"></app-meeting-purchase-canceled>
  <app-meeting-participant-joined
    *ngSwitchCase="noticeTypes.meeting_participant_joined" [notification]="notification"></app-meeting-participant-joined>
  <app-contract-prolong-order-unpaid
    *ngSwitchCase="noticeTypes.contract_prolong_order_unpaid" [notification]="notification"></app-contract-prolong-order-unpaid>
  <app-contract-signed-up
    *ngSwitchCase="noticeTypes.contract_signed_up" [notification]="notification"></app-contract-signed-up>
  <app-contract-signed-up-student
    *ngSwitchCase="noticeTypes.contract_signed_up_student" [notification]="notification"></app-contract-signed-up-student>
  <app-meeting-with-teacher-will-start
    *ngSwitchCase="noticeTypes.meeting_with_teacher_will_start" [notification]="notification"></app-meeting-with-teacher-will-start>
  <app-meeting-will-start
    *ngSwitchCase="noticeTypes.meeting_will_start" [notification]="notification"></app-meeting-will-start>
  <app-meeting-with-student-will-start
    *ngSwitchCase="noticeTypes.meeting_with_student_will_start" [notification]="notification"></app-meeting-with-student-will-start>
  <app-you-successfully-enrolled-in-meeting
    *ngSwitchCase="noticeTypes.you_successfully_enrolled_in_meeting" [notification]="notification"></app-you-successfully-enrolled-in-meeting>
  <app-student-successfully-enrolled-in-meeting
    *ngSwitchCase="noticeTypes.student_successfully_enrolled_in_meeting" [notification]="notification"></app-student-successfully-enrolled-in-meeting>

  <!--Rating, Likes, Comments notifications-->
  <app-content-comment
    *ngSwitchCase="noticeTypes.content_comment" [notification]="notification"></app-content-comment>
  <app-content-like
    *ngSwitchCase="noticeTypes.content_like" [notification]="notification"></app-content-like>
  <app-subscriber
    *ngSwitchCase="noticeTypes.subscriber" [notification]="notification"></app-subscriber>

<!--Moderation notifications-->
  <app-moderation-update
    *ngSwitchCase="noticeTypes.content_moderation_updated" [notification]="notification"></app-moderation-update>
<!--  Billing operations-->
  <app-billing-plan-was-deactivated
    *ngSwitchCase="noticeTypes.billing_plan_was_deactivated" [notification]="notification"></app-billing-plan-was-deactivated>
  <app-billing-plan-will-be-prolong-soon
    *ngSwitchCase="noticeTypes.billing_plan_will_be_prolong_soon" [notification]="notification"></app-billing-plan-will-be-prolong-soon>

<!--Profile status notifications-->
  <app-profile-declined
    *ngSwitchCase="noticeTypes.profile_declined" [notification]="notification"></app-profile-declined>
  <app-profile-verified
    *ngSwitchCase="noticeTypes.profile_verified" [notification]="notification"></app-profile-verified>

  <!--Classroom notifications-->
  <app-classroom-new-invitation-2
    *ngSwitchCase="noticeTypes.classroom_new_invitation_2" [notification]="notification"></app-classroom-new-invitation-2>
  <app-classroom-new-participant-2
    *ngSwitchCase="noticeTypes.classroom_new_participant_2" [notification]="notification"></app-classroom-new-participant-2>
  <app-classroom-removed
    *ngSwitchCase="noticeTypes.classroom_was_removed" [notification]="notification"></app-classroom-removed>
  <app-classroom-leave
    *ngSwitchCase="noticeTypes.classroom_user_leave" [notification]="notification"></app-classroom-leave>

<!--Complaint notifications-->
  <app-meeting-complaint-reported
    *ngSwitchCase="noticeTypes.meeting_complaint_reported" [notification]="notification"></app-meeting-complaint-reported>
  <app-meeting-complaint-resolved-teacher
    *ngSwitchCase="noticeTypes.meeting_complaint_resolved_teacher" [notification]="notification"></app-meeting-complaint-resolved-teacher>
  <app-meeting-complaint-resolved-reporter
    *ngSwitchCase="noticeTypes.meeting_complaint_resolved_reporter" [notification]="notification"></app-meeting-complaint-resolved-reporter>

  <!--Vacation notifications-->
  <app-vacation-reminder
    *ngSwitchCase="noticeTypes.vacation_reminder" [notification]="notification"></app-vacation-reminder>

  <!--Other notifications-->
  <ng-container *ngSwitchDefault>
    <div *ngIf="debug$ | async">
      Not supported
    </div>
  </ng-container>
</ng-container>
